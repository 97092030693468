.App {
    text-align: center;
    min-height: 100vh;
    display: block;
    position: static;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.middle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
